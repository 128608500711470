import React from "react"

import Layout from "../../components/Layout"
import { StaticImage } from "gatsby-plugin-image"
import Hero from "../../components/Hero"
import RibbonText from "../../components/ui/RibbonText"
import Testimonial from "../../components/ui/Testimonial"
import { graphql } from "gatsby"
import ReactMarkdown from "react-markdown"
import Seo from "../../components/Seo"

export default function Testimonials({ data }) {
  const testimonials = data.allStrapiTestimonials.nodes

  return (
    <Layout>
      <Seo title="Testimonials" />
      <Hero
        content={
          <h1 className="max-w-md !normal-case mt-auto">Testimonials</h1>
        }
        background={
          <StaticImage
            src="../../images/builder-woman-construction.jpg"
            className="w-full h-full"
            quality={100}
            objectPosition="20% 50%"
            alt="A beautiful modern house with a pool and deck lit in a violet sunset."
          />
        }
      />
      <section className="wrapper py-8 sm:py-16">
        <div className="container">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-5 md:gap-10">
            <h2 className="md:col-span-2 sm:w-1/2">
              We’ve helped many secure their building objectives.
            </h2>
            <RibbonText
              className="justify-self-end"
              text="What are people"
              highlighted="saying"
              justify="right"
            />
            <div
              className="hidden md:inline"
              style={{
                columnCount: 2,
                "column-gap": "4rem",
                "grid-column": "span 3 / span 3",
              }}
            >
              <div className="inline-block" />
              {testimonials.map(testimonial => (
                <Testimonial
                  className="inline-block mb-8"
                  details={testimonial.suburb}
                  name={testimonial.names}
                  quote={
                    <ReactMarkdown className="testimonial">
                      {testimonial.quote}
                    </ReactMarkdown>
                  }
                />
              ))}
            </div>
            <div className="md:hidden">
              {testimonials.map(testimonial => (
                <Testimonial
                  className="inline-block mb-8 sm:mb-6"
                  details={testimonial.suburb}
                  name={testimonial.names}
                  quote={
                    <ReactMarkdown className="testimonial">
                      {testimonial.quote}
                    </ReactMarkdown>
                  }
                />
              ))}
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query TestimonialsQuery {
    allStrapiTestimonials {
      nodes {
        quote
        names
        suburb
      }
    }
  }
`
