import React from "react"
import Card from "./Card"

export default function Testimonial({ quote, name, details, className }) {
  return (
    <Card className={className}>
      <div className="flex flex-col h-full">
        <p className="opacity-75 pb-2">{quote}</p>
        <div className="subtitle text-primary flex flex-row justify-between sm:inline">
          <p>{name}</p>
          <p className="text-black opacity-50">{details}</p>
        </div>
      </div>
    </Card>
  )
}
